import {TakipEntity} from "@/entity/TakipEntity";
import AlacakKalemiEntity from "@/entity/AlacakBelgeler/AlacakKalemiEntity";
import {AlacakKalemiTuru} from "@/enum/AlacakKalemiTuru";
import {IlamsizAlacakKalemiEntity} from "@/entity/AlacakBelgeler/IlamsizAlacakKalemiEntity";
import {FaizEntity} from "@/entity/FaizEntity";
import {FaizOranGecmisEntity} from "@/entity/FaizOranGecmisEntity";
import {Helper} from "@/plugins/helper";
import {TahsilatEntity} from "@/entity/TahsilatEntity";
import {FaizHesapDonemEntity} from "@/entity/FaizHesapDonemEntity";
import { BorcluEntity } from '@/entity/BorcluEntity';

export class AlacakKalemiFaizHesapDetay {
    baslangicTarihi: Date | null=null;
    bitisTarihi: Date | null=null;
    tutar: number |null=null;
    yillikFaiz: number | null =null;
    gecenGun: number | null = null;
    birGundeIsleyen: number | null = null;
    donemlikToplam: number | null = null;
    tahsilat: number | null = null;
    donemBasiAsilAlacak: number | null = null;
    donemBasiFaizAlacagi: number | null = null;
    faizdenDusenTahsilat: number | null = null;
    anaparadanDusenTahsilat: number | null = null;
    donemSonuFaizAlacagi: number | null = null;
    tahsilatSonrasiKalanAnapara: number | null = null;
    toplamBorc: number | null = null;
}


export class AlacakKalemiFaizHesap {
    alacakKalemi:AlacakKalemiEntity;
    faizOranGecmisi:Array<FaizOranGecmisEntity> | null = null;
    tahsilatlar: Array<TahsilatEntity> | null = [];
    donemler:Array<FaizHesapDonemEntity>|null=[];
    detaylar: Array<AlacakKalemiFaizHesapDetay> = [];
    faizToplam:number=0;
    constructor(alacakKalemi: AlacakKalemiEntity, faizGecmis: Array<FaizOranGecmisEntity>|null, tahsilatlar: Array<TahsilatEntity>|null) {
        this.alacakKalemi = alacakKalemi;
        this.faizOranGecmisi = faizGecmis;
        this.tahsilatlar = tahsilatlar;
     //   console.log("alacakKalemi: ",this.alacakKalemi);
     //   console.log("faizGecmis: ",this.faizOranGecmisi);
        this.DonemleriOlustur();
        console.log("Dönemler: ",this.donemler);
    }
    private DonemleriOlustur(){
        let sayac = 0;
        this.faizOranGecmisi?.forEach((faizDonem: FaizOranGecmisEntity) =>{
                this.tahsilatlar?.forEach((tahsilat:TahsilatEntity)=>{
                    let donem = new FaizHesapDonemEntity();
                     if (tahsilat.tarih !==null && faizDonem.baslangicTarihi !== null && faizDonem.bitisTarihi !==null && tahsilat.tarih > faizDonem.baslangicTarihi && tahsilat.tarih < faizDonem.bitisTarihi){
                         let donem2 = new FaizHesapDonemEntity();
                         donem.baslangicTarihi = faizDonem.baslangicTarihi;
                         donem.bitisTarihi = tahsilat.tarih;
                         donem.tahsilatTutar = 0;
                         donem.oran = faizDonem.oran;
                         donem2.bitisTarihi = faizDonem.bitisTarihi;
                         donem2.baslangicTarihi = tahsilat.tarih;
                         donem2.tahsilatTutar = tahsilat.tutar;
                         donem2.oran = faizDonem.oran;
                         this.donemler?.push(donem);
                         this.donemler?.push(donem2);
                    }
                     else
                     {
                         donem.baslangicTarihi = faizDonem.baslangicTarihi;
                         donem.bitisTarihi = faizDonem.bitisTarihi;
                         donem.tahsilatTutar = 0;
                         donem.oran = faizDonem.oran;
                         if (sayac==0){
                            this.donemler?.push(donem);
                             sayac =2;
                         }
                         sayac--;
                     }
                })
        })

    }
}

export class FaizHesaplayiciv2 {
    takip: TakipEntity;
    faiz: FaizEntity;

    hesaplar: Array<AlacakKalemiFaizHesap> = [];

    toplam!: number;

    constructor(takip: TakipEntity, faiz: FaizEntity) {
        this.takip = takip;
        this.faiz = faiz;
    }

    public hesapla() {
        let tahsilatlar = Array<TahsilatEntity>();
        this.takip.borclular?.forEach((borclu: BorcluEntity) => {
            borclu.tahsilatlar?.forEach((tahsilat: TahsilatEntity) => {
                tahsilatlar.push(tahsilat);
            });
        });

        this.takip.alacakKalemleri?.forEach((alacakKalemi: AlacakKalemiEntity) => {
            if (alacakKalemi.alacakKalemiTuru == AlacakKalemiTuru.ilamsiz) {
                this.hesaplar.push( this.yillik360Hesabi(alacakKalemi, tahsilatlar) );
            }
        })
    }


    protected yillik360Hesabi(alacakKalemi:AlacakKalemiEntity, tahsilatlar:Array<TahsilatEntity>|null): AlacakKalemiFaizHesap {
        let hesap = new AlacakKalemiFaizHesap(alacakKalemi,this.faiz.faizGecmis,tahsilatlar);
        var alacakToplam = 0;
        const helper = new Helper();
        // console.log("yillik360Hesabi",hesap)

        hesap.donemler?.forEach((faizDonem: FaizHesapDonemEntity) => {
            // console.log(" - - - - - - - - - - ", i);
            // console.log("faizGecmisForEach::faizDonem", faizDonem);
            // console.log("faizDonemBasTarihIsDate",faizDonem.baslangicTarihi instanceof Date);
            // console.log("vadeTarihiIsDate",hesap.alacakKalemi.vadeTarihi instanceof Date);
            // console.log("faizDonemBisitTarihiIsDate",faizDonem.bitisTarihi instanceof Date)
            // console.log("faizDonemVadeTarihComparisonTo FaizDonemBasTarih",faizDonem.baslangicTarihi < hesap.alacakKalemi.vadeTarihi);
            // console.log("faizDonemVadeTarihComparisonTo FaizDonemBitisTarih",faizDonem.baslangicTarihi < hesap.alacakKalemi.vadeTarihi);

           // console.log("faizDonem.baslangicTarihi",faizDonem.baslangicTarihi);
           // console.log("faizDonem.bitisTarihi",faizDonem.bitisTarihi);
          //  console.log("hesap.alacakKalemi.vadeTarihi",hesap.alacakKalemi.vadeTarihi);
            if (
                faizDonem.baslangicTarihi instanceof Date &&
                hesap.alacakKalemi.vadeTarihi instanceof Date &&
                faizDonem.bitisTarihi instanceof Date &&
                this.takip.takipTalebi?.takipTarihi  instanceof Date &&
                (
                    (
                        faizDonem.baslangicTarihi < hesap.alacakKalemi.vadeTarihi &&
                        faizDonem.bitisTarihi > hesap.alacakKalemi.vadeTarihi
                    )
                    ||
                    (
                        faizDonem.baslangicTarihi < this.takip.takipTalebi?.takipTarihi &&
                        faizDonem.bitisTarihi > this.takip.takipTalebi?.takipTarihi
                    )
                    ||
                    (
                        faizDonem.baslangicTarihi > hesap.alacakKalemi.vadeTarihi  &&
                        faizDonem.bitisTarihi < this.takip.takipTalebi?.takipTarihi
                    )

                )

            ) {

                let hesapDetay = new AlacakKalemiFaizHesapDetay();
                if (hesap.alacakKalemi.tutar!=null && faizDonem.oran != null && hesapDetay.yillikFaiz != null) {
                    hesapDetay.yillikFaiz = hesap.alacakKalemi.tutar * faizDonem.oran/100;
                    hesapDetay.birGundeIsleyen = hesapDetay.yillikFaiz / 360;
                }
                if (hesap.alacakKalemi.vadeTarihi > faizDonem.baslangicTarihi) {
                    hesapDetay.baslangicTarihi = hesap.alacakKalemi.vadeTarihi;
                } else {
                    hesapDetay.baslangicTarihi = faizDonem.baslangicTarihi;
                }


                if (this.takip.takipTalebi?.takipTarihi > faizDonem.bitisTarihi) {
                    hesapDetay.bitisTarihi = faizDonem.bitisTarihi;
                }else{
                    hesapDetay.bitisTarihi = this.takip.takipTalebi?.takipTarihi;
                }

                hesapDetay.gecenGun = helper.tarihFarkiGunSayisi( hesapDetay.baslangicTarihi, hesapDetay.bitisTarihi);
                if (hesapDetay.birGundeIsleyen!=null)
                    hesapDetay.donemlikToplam = hesapDetay.gecenGun * hesapDetay.birGundeIsleyen;
 /*     BURADA BAZILARI SABİT GELECEK BAZILARI FOREACH DÖNDÜKÇE HESAPLANACAK
        BAZI ALANLAR hesapDetay yerine hesap'ta olabilir
                hesapDetay.tahsilat = faizDonem.tahsilatTutar;
                hesapDetay.donemBasiAsilAlacak = alacakKalemi.tutar;
                hesapDetay.donemBasiFaizAlacagi = 0;
                hesapDetay.faizdenDusenTahsilat = 0;
                hesapDetay.donemSonuFaizAlacagi = 0;

  */
                hesap.detaylar.push(hesapDetay);
                if (hesapDetay.donemlikToplam!=null)
                    hesap.faizToplam += hesapDetay.donemlikToplam;
            }else{

            }
        })
        return hesap;
    }

    protected BugunuGetir(): Date {

        var Today = new Date(Date.now()).toLocaleString();
        return new Date();
    }


}
