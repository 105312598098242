









































import {Component, Vue} from "vue-property-decorator";
import Tutar from "@/components/inputs/Tutar.vue";
import ItemArrayManager from "@/components/common-inputs/ItemArrayManager.vue";
import {ParaBirimiListResponseProvider} from "@/services/ParaBirimiService";
import {ItemArrayManagerTableHeader} from "@/interface/TableHeader";
import {TahsilatListResponseProvider} from "@/services/TahsilatService";
import TahsilatForm from "@/components/anakart/tahsilat/TahsilatForm.vue";
import OranList from "@/components/tercihler/OranList.vue";
import {FaizListResponseProvider} from "@/services/FaizService";
import {FaizHesaplayiciv2} from "@/classes/faizHesaplayici/FaizHesaplayiciv2";
import {TakipListResponseProvider} from "@/services/TakipService";
import VekaletDonemList from "@/components/tercihler/VekaletDonemList.vue";
import TakipList from "@/components/takip/TakipList.vue";
import {TakipEntity} from "@/entity/TakipEntity";

@Component({
    components: {
        TakipList,
        VekaletDonemList,
        OranList,
        TahsilatForm,
        ItemArrayManager,
        Tutar,
    },
})
export default class Playground extends Vue {
    items: TakipEntity[] | null = null;

    mounted() {
        window.setTimeout(() => {
            this.items = TakipListResponseProvider().data;
            console.log("takipler:", this.items);
        }, 500)
    }
    anaPara = 0;
    faizHesabi= new FaizHesaplayiciv2( TakipListResponseProvider().data[0], FaizListResponseProvider().data[0] );
    paraBirimi = ParaBirimiListResponseProvider().data[0];
    tahsilatlar = TahsilatListResponseProvider().data;
    oranList = FaizListResponseProvider().data[0];
    tahsilatHeaders: Array<ItemArrayManagerTableHeader> = [
        {value: "tarih", text: "Tarih"},
        {value: "tutar", text: "Tutar"},
        {value: "borclu.kisiKurum.ad", text: "Borclu"},
    ];
    Hesapla(){
        this.faizHesabi.hesapla();
    }
}

